<template>
    <section>
        <div class="bg-white br-12 py-3 px-3 mt-3">
            <div class="row mx-0">
                <p class="col-12 px-0 f-18 f-500 text-general">
                    Compras por convenio vs pago de contado de los últimos 12 meses
                </p>
            </div>
            <div class="d-flex">
                <div class="col-auto px-0">
                    <div class="bg-fondo mt-5 br-12 px-2 py-2 text-center" style="height:100px;width:158px;">
                        Compras totales por convenio
                        <p>
                            {{ convertMoney(comprasTotalesConvenio) }}
                        </p>
                    </div>
                    <div class="bg-general mt-5 br-12 px-2 py-2 text-white text-center" style="height:100px;width:158px;">
                        Pedidos totales por convenio
                        <p>
                            {{ agregarSeparadoresNumero(pedidosTotalesConvenio) }}
                        </p>
                    </div>
                </div>
                <div class="col w-100">
                    <echart :options="compras" class="w-100" />
                </div>
                <div class="col-auto px-0">
                    <div class="bg-fondo mt-5 br-12 px-2 py-2 text-center text-white" style="height:100px;width:158px;background-color:#637381">
                        Compras totales de contado
                        <p>
                            {{ convertMoney(comprasTotalesContado) }}
                        </p>
                    </div>
                    <div class="mt-5 br-12 px-2 py-2 text-center text-white" style="height:100px;width:158px;background-color:black">
                        Pedidos totales de contado
                        <p>
                            {{ agregarSeparadoresNumero(pedidosTotalesContado) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-content-start mt-5 mb-3">
            <div class="col-auto d-middle text-general f-17 f-500">
                Rango de análisis
            </div>
            <div class="col-3">
                <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-3 px-0 mx-2 date-picker-edit">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="small"
                :disabled="disable_fecha"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="daterange"
                unlink-panels
                popper-class="date-picker-edit-popper"
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                />
            </div>
            <div class="col-auto">
                <div class="btn-general bg-general text-white p-1 px-3 f-14 br-8" @click="conMasCompras()">
                    Mostrar informe
                </div>
            </div>
        </div>
        <div class="bg-white br-12 py-3 px-3 mt-3">
            <div class="row mx-0">
                <p class="col-12 px-0 f-18 f-500 text-general">
                    Afiliados con más compras
                </p>
            </div>
            <div class="d-flex">
                <div class="col-auto px-0">
                    <div class="bg-fondo mt-5 br-12 px-2 py-2 text-center" style="height:100px;width:158px;">
                        Afiliados que compraron
                        <p>
                            {{ agregarSeparadoresNumero(masComprasAfiliadosTotales) }}
                        </p>
                    </div>
                    <div class="bg-fondo mt-5 br-12 px-2 py-2 text-center" style="height:100px;width:158px;">
                        Compras totales
                        <p>
                            {{ convertMoney(masComprasTotales) }}
                        </p>
                    </div>
                </div>
                <div class="col w-100">
                    <echart ref="conComprasGrafica" :options="conCompras" class="w-100" />
                </div>
                <div class="col-auto px-0">
                    <div class="mt-5 br-12 px-2 py-2 text-center text-white" style="height:100px;width:158px;background-color:#637381">
                        Afiliados que no compraron
                        <p>
                            {{ agregarSeparadoresNumero(afiliadosNoCompraron) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <modalComprasAfiliado ref="modalComprasAfiliado" />
    </section>
</template>




<script>
import nominaDetalleEstadisticas from '~/services/nomina/nominaDetalleEstadisticas'
const colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
import moment from 'moment'

export default {
    components: {
        modalComprasAfiliado: () => import('../partials/modalComprasAfiliado'),
    },
    data(){
        return {
            compras: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Convenio', 'De contado', 'Pedidos por convenio', 'Pedido de contado']
                },
                xAxis: {
                    type: 'category',
                    show: false
                },
                dataset:{
                    dimensions:['fecha', 'sum_convenio', 'sum_sin_convenio', 'count_convenio', 'count_sin_convenio'],
                    source: []
                },
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        min: 0,
                        axisLabel: {
                            formatter: '$ {value}'
                        }
                    },
                    {
                        type: 'value',
                        position: 'right',
                        alignTicks: true,
                        axisLine: {
                            show: true,
                        },
                       
                    }
                ],
                series: [
                    {
                        name: 'Convenio',
                        type: 'bar',
                        barWidth: 50,
                        stack: 'stack',
                        itemStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    {
                        name: 'De contado',
                        type: 'bar',
                        barWidth: 50,
                        stack: 'stack',
                        itemStyle: {
                            color: '#637381'
                        }
                    },
                    {
                        name: 'Pedidos por convenio',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedido de contado',
                        type: 'line',
                        symbol:'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            color: '#000000'
                        },
                    },
                ]
            },
            conCompras: {
                tooltip: {
                    trigger: 'axis',
                    formatter: params => {
                        const data = params[0].data
                        const de = this.convertMoney(data.rango[0])
                        const hasta = this.convertMoney(data.rango[1])
                        const suma = this.convertMoney(data.sum)

                        return `
                            De ${de} a ${hasta}<br />
                            ${data.count} Afiliados \t\t\t ${suma}
                            
                        `
                    }
                },
                xAxis: {
                    type: 'category',
                    show: false
                },
                dataset:{
                    dimensions:['label', 'sum'],
                    source: []
                },
                yAxis: 
                    {
                        type: 'value',
                        scale: true,
                        min: 0,
                        axisLabel: {
                            formatter: '$ {value}'
                        }
                    },
                    
                series: [
                    {
                        name: 'Convenio',
                        type: 'bar',
                        barWidth: 50,
                        stack: 'stack',
                        itemStyle: {
                            color: '#DFE4E8'
                        }
                    },
                ]
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            afiliadosNoCompraron: 0,
        }
    },
    computed: {
        comprasTotalesConvenio(){
            let data = this.compras.dataset.source
            return _.sumBy(data, el => Number(el.sum_convenio)) || 0
        },
        pedidosTotalesConvenio(){
            let data = this.compras.dataset.source
            return _.sumBy(data, el => Number(el.count_convenio)) || 0
        },
        comprasTotalesContado(){
            let data = this.compras.dataset.source
            return _.sumBy(data, el => Number(el.sum_sin_convenio)) || 0
        },
        pedidosTotalesContado(){
            let data = this.compras.dataset.source
            return _.sumBy(data, el => Number(el.count_sin_convenio)) || 0
        },
        masComprasAfiliadosTotales(){
            let data = this.conCompras.dataset.source
            return _.sumBy(data, el => Number(el.count)) || 0
        },
        masComprasTotales(){
            let data = this.conCompras.dataset.source
            return _.sumBy(data, el => Number(el.sum)) || 0
        },
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
    },
    mounted(){
        this.$refs.conComprasGrafica.instance.on('click', (params) => {
            this.verDetalleCompro(params);
        })
        this.buscarData()
        this.conMasCompras()
    },
    methods: {
        async buscarData(){
            try {
                const id = this.$route.params.id_convenio
                
                const { data } = await nominaDetalleEstadisticas.grafica12Meses(id);
                this.compras.dataset.source = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async conMasCompras(){
            try {
                const params = {
                    convenio: this.$route.params.id_convenio,
                    fechas: this.fecha
                }
                
                const { data } = await nominaDetalleEstadisticas.conMasCompras(params);
                this.afiliadosNoCompraron = data.noCompraron
                this.conCompras.dataset.source = data.rangos
            } catch (error){
                this.error_catch(error)
            }
        },
        verDetalleCompro(params){
            this.$refs.modalComprasAfiliado.toggle(params.data, this.fecha);
        },
    }
}
</script>   